import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authcontext';
import axios from 'axios';
import Navbar from '../../components/Navbar';
import Leftnav from '../../components/Leftnav';
import { downloadInvoice } from '../../utils/invoice';
import { downloadShippingLable } from '../../utils/shippinglabel';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import { toast } from 'react-toastify';

const OrderDetail = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { _id } = useParams();
  const [customerDetails, setCustomerDetails] = useState({
    name: '',
    address: '',
    phoneNo: '',
    dialcode: '',
    country: '',
    state: '',
    date: '',
    city: '',
    pincode: '',
    status: 'Pending',
    trackingId: '',
    currency: '',
    order_id: '',
  });
  const [currency, setCurrency] = useState('INR');
  const [order_id, setOrderId] = useState();
  const [invoice_id, setInvoiceId] = useState();
  const [products, setProducts] = useState([]);
  const [totals, setTotals] = useState({ total: 0, gst: 0, deliveryCharge: 0, grandTotal: 0 });
  const [isProductDetailsOpen, setIsProductDetailsOpen] = useState(false);
  const [isCustomerDetailsOpen, setIsCustomerDetailsOpen] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const searchTimeout = useRef(null);
  const itiRef = useRef(null);
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(!user){
      navigate('/');
    }else{
      if (_id) {
        fetchDataById(_id);
      }
    }
  }, [_id]);

  useEffect(() => {
    if (products.length > 0) {
      calculateTotals(products);
    }
  }, [currency, products]);

  useEffect(() => {
    if (inputRef.current) {
      itiRef.current = intlTelInput(inputRef.current, {
        initialCountry: 'in',
        preferredCountries: ['in', 'us'],
        separateDialCode: true,
        utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
      });
      if(customerDetails.dialcode){
        itiRef.current.setNumber(customerDetails.dialcode+customerDetails.phoneNo);
      }
    }
    return () => {
      if (itiRef.current) {
        itiRef.current.destroy();
      }
    };
  }, [inputRef.current]);

  

  const fetchDataById = async (_id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}order-detail/${_id}`,{
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "application/json"
        },
      });
      if (response.ok) {
        const data = await response.json();
        const { order, products } = data;

        setCustomerDetails({
          name: order.customer_name,
          phoneNo: order.mobile,
          dialcode: order.dialcode,
          country: order.country,
          state: order.state,
          email: order.email,
          date: order.ordered_date.slice(0, 10),
          city: order.city,
          pincode: order.postalCode,
          address: order.address,
          status: order.status,
          trackingId: order.trackingId ?? '',
          currency: order.currency,
          order_id: order.order_id ?? '',
        });

        setCurrency(order.currency);
        setOrderId(order.order_id);
        setInvoiceId(order.invoice_id);

        const updatedProducts = products.map(product => {
          const orderProduct = order.products.find(p => p.product_id === product.id);
          return {
            ...product,
            quantity: orderProduct ? orderProduct.quantity : 0,
            subTotal: (orderProduct ? orderProduct.quantity : 0) * product.price
          };
        });

        setProducts(updatedProducts);
        calculateTotals(updatedProducts);
      } else {
        console.error('Failed to fetch order details');
      }
    } catch (error) {
      console.error('Error fetching order:', error);
    }
  };

  const fetchProductList = async (query) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}search-order-product?query=${query}`,{
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "application/json"
        },
      });
      if (response.ok) {
        const data = await response.json();
        setProductList(data || []);
      } else {
        console.error('Failed to fetch products');
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleSearchInput = (e, index) => {
    const query = e.target.value;
    setSearchQuery(query);
    setFocusedInput(index);
    
    clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(() => {
      if (query) {
        fetchProductList(query);
      } else {
        setProductList([]);
      }
    }, 500);
  };

  const handleSelectProduct = (product) => {
    if (focusedInput !== null) {
      const index = focusedInput;
      const quantity = 1;
      const price = currency === 'INR' ? product.inrprice : product.usdprice;
  
      const newProducts = [...products];
      newProducts[index] = {
        ...product, 
        quantity, 
        price, 
        subTotal: quantity * price,
      };
      setProducts(newProducts);

      calculateTotals(newProducts);
  
      setSearchQuery('');
      setProductList([]);
      setFocusedInput(null);
    }
  };
  

  const handleCustomerChange = (e) => {
    setCustomerDetails(prevDetails => ({ ...prevDetails, [e.target.name]: e.target.value }));
  };

  const handleProductChange = (index, e) => {
    const { name, value } = e.target;
    const newProducts = [...products];
    newProducts[index][name] = value;

    if (name === 'price' || name === 'quantity') {
      newProducts[index].subTotal = (newProducts[index].price || 0) * (newProducts[index].quantity || 0);
    }

    setProducts(newProducts);
    calculateTotals(newProducts);
  };

  const calculateTotals = (products) => {
    const total = products.reduce((sum, product) => sum + (product.subTotal || 0), 0);
    let deliveryCharge;
    if(currency === 'INR'){
      deliveryCharge = total >= 4000 ? 350 : 150;
    }else{
      deliveryCharge = total >= 130 ? 35 : total < 20 ? 18 : 20;
    }
    const grandTotal = total + deliveryCharge;

    setTotals({ total, deliveryCharge, grandTotal });
  };

  const addProduct = () => {
    setProducts([...products, { name: '', price: 0, quantity: 1, subTotal: 0 }]);
  };

  const toggleProductDetails = () => {
    setIsProductDetailsOpen(prev => !prev);
  };

  const toggleCustomerDetails = () => {
    setIsCustomerDetailsOpen(prev => !prev);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const { name, phoneNo, address, country,state,city,pincode, email } = customerDetails;

    if (!name || !phoneNo || !address || !country || !state || !city || !pincode || !email ) {
      setLoading(false);
      alert('Please fill in all required fields: Name, Phonr no. ,Address, Country, state, city, pincode, email.');
      return;
    }
    const dialCodeElement = document.getElementsByClassName('iti__selected-dial-code')[0];
    const dialCode = dialCodeElement ? dialCodeElement.innerText : '+91';
    const iti = itiRef.current;

    if (!phoneNo.trim()) {
        setLoading(false);
        alert('Phone Number is required');
    } else if (!iti.isValidNumber()) {
        setLoading(false);
        alert('Invalid phone number');
    } else {
      customerDetails.dialcode = dialCode;
    }
    

    try {

      const data = JSON.stringify({
        _id,
        customerDetails,
        products,
        totals,
        currency
      });

      const response = await axios.post(`${process.env.REACT_APP_API_URL}order-update`, 
        data, {
          headers: {
            "Authorization": `Bearer ${user}`,
            "Content-Type": "application/json"
        },
      });
  
      if (response.status === 200) {
        toast.success('Order updated successfully!', { position: 'top-right' });
        setLoading(false);
        navigate('/orders');
      } else {
        setLoading(false);
        console.error('Failed to update order:', response.data.message);
        toast.success(`Failed to update order: ${response.data.message}`, { position: 'top-right' });
      }
    } catch (error) {
      setLoading(false);
      console.error('Error updating order:', error);
      toast.success('Error updating order!', { position: 'top-right' });
    }
  };
  

  return (
    <>
      <Navbar />
      <Leftnav />
      <div className="row-container">
        <div className='page_title'>
          <h1 className="title">Order Info</h1>
        </div>
        <div className="order-container">
          <div className="accordion">
            <div className="accordion-item">
              <div className="accordion-header">
                <button className="accordion-button" onClick={toggleCustomerDetails}>
                  Customer Details
                </button>
              </div>
              {isCustomerDetailsOpen && (
                <div className="accordion-content">
                  <div className='form-container'>

                      <div className='form-group'>
                        <input
                          type="text"
                          placeholder="Name"
                          name="name"
                          value={customerDetails.name}
                          onChange={handleCustomerChange}
                          className="input"
                        />
                      </div>

                      <div className='form-group'>
                        <input
                          type="tel"
                          ref={inputRef}
                          name="phoneNo"
                          value={customerDetails.phoneNo}
                          onChange={handleCustomerChange}
                          className="input"
                        />
                      </div>


                    <div className='form-group'>
                      <input
                        type="text"
                        placeholder="Country"
                        name="country"
                        value={customerDetails.country}
                        onChange={handleCustomerChange}
                        className="input"
                      />
                    </div>

                    <div className='form-group'>
                      <input
                        type="text"
                        placeholder="State"
                        name="state"
                        value={customerDetails.state}
                        onChange={handleCustomerChange}
                        className="input"
                      />
                    </div>

                    <div className='form-group'>
                      <input
                        type="text"
                        placeholder="City"
                        name="city"
                        value={customerDetails.city}
                        onChange={handleCustomerChange}
                        className="input"
                      />
                    </div>


                    <div className='form-group'>
                      <input
                        type="text"
                        placeholder="Pincode"
                        name="pincode"
                        value={customerDetails.pincode}
                        onChange={handleCustomerChange}
                        className="input"
                      />
                    </div>

                      <div className='form-group'>
                        <input
                          type="text"
                          placeholder="email"
                          name="email"
                          value={customerDetails.email}
                          onChange={handleCustomerChange}
                          className="input"
                        />
                      </div>


                    <div className='form-group'>
                      <select
                        name="currency"
                        value={currency}
                        onChange={(e) => setCurrency(e.target.value)}
                        className="input"
                      >
                        <option value="INR">INR</option>
                        <option value="USD">USD</option>
                      </select>
                    </div>


                    <div className='form-group'>
                      <input
                        type="date"
                        name="date"
                        value={customerDetails.date}
                        onChange={handleCustomerChange}
                        className="input"
                      />
                    </div>

                    <div className='form-group'>
                      <input
                        type="text"
                        name="order_id"
                        value={customerDetails.order_id}
                        onChange={handleCustomerChange}
                        className="input"
                        disabled
                      />
                    </div>

                    {customerDetails.status === 'Shipped' && (
                      <div className='form-group'>
                      <input
                        type="text"
                        placeholder="Tracking Id"
                        name="trackingId"
                        value={customerDetails.trackingId}
                        onChange={handleCustomerChange}
                        className="input"
                      />
                    </div>
                    )}

                    <div style={{ width: '100%' }}>
                      <textarea
                        name="address"
                        placeholder='Enter Address'
                        value={customerDetails.address}
                        onChange={handleCustomerChange}
                      />
                    </div>

                  </div>
                </div>
              )}
            </div>
            <div className="accordion-item">
              <div className="accordion-header">
                <button className="accordion-button" onClick={toggleProductDetails}>
                  Product Details
                </button>
              </div>
              {isProductDetailsOpen && (
                <div className="accordion-content">
                  <table className="product-table">
                    <thead>
                      <tr>
                        <th>Item Name</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Sub Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {products.map((product, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="text"
                              name="name"
                              value={product.name}
                              onChange={(e) => handleProductChange(index, e)}
                              onFocus={() => setFocusedInput(index)}
                              onBlur={() => setFocusedInput(null)}
                              onInput={(e) => handleSearchInput(e, index)}
                              className="input-table"
                            />
                            {focusedInput === index && productList.length > 0 && (
                              <ul className="search-results">
                                {productList.map((product) => (
                                  <li key={product.id} onMouseDown={() => handleSelectProduct(product)}>
                                    {product.name}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </td>
                          <td><input type="number" name="price" value={product.price} onChange={(e) => handleProductChange(index, e)} className="input-table" /></td>
                          <td><input type="number" name="quantity" value={product.quantity ?? 1} onChange={(e) => handleProductChange(index, e)} className="input-table" /></td>
                          <td><input type="number" disabled value={product.subTotal || 0} className="input-table subtotal" /></td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="3" className="totals-label">Total</td>
                        <td className='total-sum'>{totals.total}</td>
                      </tr>
                      <tr>
                        <td colSpan="3" className="totals-label">Delivery Charge</td>
                        <td className='total-sum'>{totals.deliveryCharge}</td>
                      </tr>
                      <tr>
                        <td colSpan="3" className="totals-label">Grand Total</td>
                        <td className='total-sum'>{totals.grandTotal}</td>
                      </tr>
                    </tbody>
                  </table>
                  <button onClick={addProduct} className="add-button">Add Product</button>
                </div>
              )}
            </div>
          </div>
          <div className='form-container'>
            <div className='form-group'>
            <select
                name="status"
                value={customerDetails.status}
                onChange={handleCustomerChange}
                className="input"
              >
                <option value="Pending">Pending</option>
                <option value="created">Payment Pending</option>
                <option value="paid">Payment done</option>
                <option value="crafting">Crafting</option>
                <option value="Shipped">Shipped</option>
                <option value="Delivered">Delivered</option>
                <option value="Delayed">Delayed</option>
              </select>

            </div>
            <div className='form-group order-button'>
              <button onClick={handleSubmit} disabled={loading} className="submit-button">
                {_id ? (loading ? "Updating..." : "Update") : (loading ? "Submitting..." : "Submit")}
              </button>
              <button onClick={() => downloadInvoice(customerDetails, products, totals,order_id,invoice_id)} className="invoice-button">Invoice</button>
              <button onClick={() => downloadShippingLable(customerDetails, order_id)} className="invoice-button">Shipping Label</button>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetail;
